import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'address' })
export class AddressPipe implements PipeTransform {

    transform(value: string, args: string[]): string {

        const address:string = value
        const city:string = args.length>0 ? args[0] : ''
        const state:string = args.length>1 ? args[1] : ''
        const zip:string = args.length>2 ? args[2] : ''

        let modstreet = address ? `${address}` : ''
        if(city || state || zip) modstreet += ','


		let modcitystate = ''
		const modzip = zip ? ` ${zip}` : ''

		if(city && state)
			modcitystate = ` ${city}, ${state}`
		else if(city)
			modcitystate = ` ${city}`
		else if(state)
			modcitystate = ` ${state}`

		return (`${modstreet}${modcitystate}${modzip}`).trim()

   }
}