<div class="nsm-field-control-wrapper" [class.has-errors]="shouldShowErrors()">
    <label [attr.for]="id" class="nsm-field-label"
        >{{ label }} <nsm-required-marker *ngIf="required" [control]="control"> </nsm-required-marker>
    </label>
    <div class="nsm-field-control-group" [formGroup]="group" [class.has-icon]="iconClass != null">
        <span *ngIf="iconClass" class="icon {{ iconClass }}"></span>
        <span *ngFor="let option of options; let i = index" style="padding-right: 50px">
            <input
                [value]="option.value"
                [formControlName]="groupName"
                type="radio"
                [attr.name]="groupName"
                kendoRadioButton
            />
            <label>{{ option.text }}</label>
        </span>
    </div>
    <span class="field-errors" *ngIf="shouldShowErrors()">
        <ng-content select="nsm-field-error"></ng-content>
    </span>
    <span class="field-warnings">
        <ng-content select="nsm-field-warning"></ng-content>
    </span>
</div>
