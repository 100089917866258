import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

/**
 * This service is purely responsible for helping route
 * to one of the core apps.  It's not intended to have excessive deep links.
 */
@Injectable({
    providedIn: 'root'
})
export class NsmAppRouterService {
    constructor(private router: Router) {}

    routeToFundingPortal(): void {
        this.router.navigateByUrl('/woq/fs');
    }
    routeToFundingPortalAdministration(): void {
        this.router.navigateByUrl('/woq/admin/funding');
    }
    routeToUpd(): void {
        this.router.navigateByUrl('/upd');
    }
    routeToDms(): void {
        this.router.navigateByUrl('/dms');
    }
}
