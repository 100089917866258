import { Component, OnInit, ViewEncapsulation, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'nsm-callout',
    templateUrl: './callout.component.html',
    styleUrls: ['./callout.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NsmCalloutComponent implements OnInit {
    @HostBinding('class') class: string = 'nsm-callout-wrapper';

    @Input()
    title: string;

    @Input()
    warn: boolean;

    @Input()
    error: boolean;

    @Input()
    success: boolean;

    constructor() {}

    ngOnInit(): void {}
}
