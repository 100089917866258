import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { environment } from 'environments/environment';
import { HttpErrorHandler, HandleError } from '@app/core/services/error.handler.service';
import { FeatureConfiguration } from '../models/feature.configuration';
import { Cacheable } from 'ngx-cacheable';
import { of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FeatureOptionsService {
    private handleError: HandleError;

    constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('WorkOrderService');
    }

    isDynamicsEnabled(): Observable<boolean> {
        return this.getFeatureOption(environment.featureOption_DynamicsEnabled).pipe(
            map((feature: FeatureConfiguration) => {
                return feature.enabled;
            }),
            catchError(this.handleError('feature option IsDynamicsEnabled', false))
        );
    }

    isWorkersCompEnabled(): Observable<boolean> {
        return this.getFeatureOption(environment.featureOption_WorkersCompEnabled).pipe(
            map((feature: FeatureConfiguration) => {
                return feature.enabled;
            }),
            catchError(this.handleError('feature option IsWorkersCompEnabled', false))
        );
    }

    isUpdEnabled(): Observable<boolean> {
        return this.getFeatureOption(environment.featureOption_UpdEnabled).pipe(
            map((feature: FeatureConfiguration) => {
                return feature.enabled;
            }),
            catchError(this.handleError('feature option IsUpdEnabled', false))
        );
    }

    isExpressEnabled(): Observable<boolean> {
        return this.getFeatureOption(environment.featureOption_ExpressEnabled).pipe(
            map((feature: FeatureConfiguration) => {
                return feature.enabled;
            }),
            catchError(this.handleError('feature option IsExpressEnabled', false))
        );
    }

    isProviderNumberEnabled(): Observable<boolean> {
        return this.getFeatureOption(environment.featureOption_ProviderNumberEnabled).pipe(
            map((feature: FeatureConfiguration) => {
                return feature.enabled;
            }),
            catchError(this.handleError('feature option IsProviderNumberEnabled', false))
        );
    }

    isOtherRequiredDocsEnabled(): Observable<boolean> {
        return this.getFeatureOption(environment.featureOption_OtherRequiredDocsEnabled).pipe(
            map((feature: FeatureConfiguration) => {
                return feature.enabled;
            }),
            catchError(this.handleError('feature option IsOtherRequiredDocsEnabled', false))
        );
    }

    isAppMenuEnabled(): Observable<boolean> {
        return this.getFeatureOption(environment.featureOption_AppMenuEnabled).pipe(
            map((feature: FeatureConfiguration) => {
                return feature.enabled;
            }),
            catchError(this.handleError('feature option IsAppMenuEnabled', false))
        );
    }

    isCompleteMirEnabled(): Observable<boolean> {
        return this.getFeatureOption(environment.featureOption_CompleteMirEnabled).pipe(
            map((feature: FeatureConfiguration) => {
                return feature.enabled;
            }),
            catchError(this.handleError('feature option IsCompleteMirEnabled', false))
        );
    }

    isReportingEnabled(): Observable<boolean> {
        return this.getFeatureOption(environment.featureOption_ReportingEnabled).pipe(
            map((feature: FeatureConfiguration) => {
                return feature.enabled;
            }),
            catchError(this.handleError('feature option IsReportingEnabled', false))
        );
    }

    isUpdPayersEnabled(): Observable<boolean> {
        return this.getFeatureOption(environment.featureOption_UpdPayersEnabled).pipe(
            map((feature: FeatureConfiguration) => {
                return feature.enabled;
            }),
            catchError(this.handleError('feature option isUpdPayersEnabled', false))
        );
    }

    isCeDocTypesEnabled(): Observable<boolean> {
        return this.getFeatureOption(environment.featureOption_UseCeDocTypes).pipe(
            map((feature: FeatureConfiguration) => {
                return feature.enabled;
            }),
            catchError(this.handleError('feature option isCeDocTypesEnabled', false))
        );
    }

    isPreDeliveryIconsEnabled(): Observable<boolean> {
        return this.getFeatureOption(environment.featureOption_UsePreDeliveryIcons).pipe(
            map((feature: FeatureConfiguration) => {
                return feature.enabled;
            }),
            catchError(this.handleError('feature option isPreDeliveryIconsEnabled', false))
        );
    }

    isCsrQueuesEnabled(): Observable<boolean> {
        return this.getFeatureOption(environment.featureOption_CsrQueuesEnabled).pipe(
            map((feature: FeatureConfiguration) => {
                return feature.enabled;
            }),
            catchError(this.handleError('feature option isCsrQueuesEnabled', false))
        );
    }

    isSecondaryReviewEnabled(): Observable<boolean> {
        return this.getFeatureOption(environment.featureOption_SecondaryReview).pipe(
            map((feature: FeatureConfiguration) => {
                return feature.enabled;
            }),
            catchError(this.handleError('feature option isSecondaryReviewEnabled', false))
        );
    }

    isExpiringAuthEnabled(): Observable<boolean> {
        return this.getFeatureOption(environment.featureOption_ExpiringAuth).pipe(
            map((feature: FeatureConfiguration) => {
                return feature.enabled;
            }),
            catchError(this.handleError('feature option isExpiringAuthEnabled', false))
        );
    }

    isDmsSendEmaiFaxEnabled(): Observable<boolean> {
        return this.getFeatureOption(environment.featureOption_DmsSendEmailFax).pipe(
            map((feature: FeatureConfiguration) => {
                return feature.enabled;
            }),
            catchError(this.handleError('feature option Dms_Send_EmailFax', false))
        );
    }

    isFundingSpecialistReSyncEnabled(): Observable<boolean> {
        return this.getFeatureOption(environment.featureOption_FundingSpecialistResync).pipe(
            map((feature: FeatureConfiguration) => {
                return feature.enabled;
            }),
            catchError(this.handleError('feature option Funding Specialist Resync', false))
        );
    }
    isPayerCredentialingEnabled(): Observable<boolean> {
        return this.getFeatureOption(environment.featureOption_PayerCredentialing).pipe(
            map((feature: FeatureConfiguration) => {
                return feature.enabled;
            }),
            catchError(this.handleError('feature option Payer Credentialing', false))
        );
    }

    isActionRequiredQueueEnabled(): Observable<boolean> {
        return this.getFeatureOption(environment.featureOption_ActionRequired).pipe(
            map((feature: FeatureConfiguration) => {
                return feature.enabled;
            }),
            catchError(this.handleError('feature option action required queue', false))
        );
    }
    isBranchLicensingEnabled(): Observable<boolean> {
        return this.getFeatureOption(environment.featureOption_BranchLicensing).pipe(
            map((feature: FeatureConfiguration) => {
                return feature.enabled;
            }),
            catchError(this.handleError('feature option Branch Licensing', false))
        );
    }
    isMedicareFeeSchedulingEnabled(): Observable<boolean> {
        return this.getFeatureOption(environment.featureOption_MedicareFeeScheduling).pipe(
            map((feature: FeatureConfiguration) => {
                return feature.enabled;
            }),
            catchError(this.handleError('feature option Medicare Fee Scheduling', false))
        );
    }
    isFSAllowableLineEditEnabled(): Observable<boolean> {
        return this.getFeatureOption(environment.featureOption_FS_ALLOWABLE_LINE_EDIT).pipe(
            map((feature: FeatureConfiguration) => {
                return feature.enabled;
            }),
            catchError(this.handleError('feature option FS allowable line edit', false))
        );
    }

    @Cacheable({
        maxAge: 1000 * 60 // 1 minute
    })
    isUPDContractsEnabled(): Observable<boolean> {
        return this.getFeatureOption(environment.featureOption_UPDContracts_Enabled).pipe(
            map((feature: FeatureConfiguration) => {
                return feature.enabled;
            }),
            catchError(this.handleError('feature option UPD Contracts', false))
        );
    }

    @Cacheable({
        maxAge: 1000 * 60 // 1 minute
    })
    private getFeatureOption(featureName: string): Observable<FeatureConfiguration> {
        const url = environment.featureOptionBaseUrl.replace('{featureName}', featureName);
        return this.http.get<Array<FeatureConfiguration>>(url).pipe(
            map((response: Array<FeatureConfiguration>) => {
                if (!response) {
                    const newconf = new FeatureConfiguration();
                    newconf.enabled = false;
                    newconf.featureName = featureName;
                    return newconf;
                } else {
                    return Object.assign(new FeatureConfiguration(), response);
                }
            })
        );
    }
}
