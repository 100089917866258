import { Component, HostListener } from '@angular/core';
import { ComponentCanDeactivate } from '../helpers/guards';

@Component({
    selector: 'app-dirty-component',
    template: ''
})
export class DirtyComponent implements ComponentCanDeactivate {
    public isDirty: boolean = false;

    @HostListener('window:beforeunload')
    canDeactivate(): boolean {
        return !this.isDirty;
    }

    makeMeDirty(): void {
        this.isDirty = true;
    }
}
