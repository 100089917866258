import { Injectable } from '@angular/core';
import { NotificationService } from '@progress/kendo-angular-notification';
import { ToastrService } from 'ngx-toastr';
import { LogType } from '../models/logType';
import { MessageType } from '../models/messageType';

/**
 * This class is responsible for displaying toast messages to the
 * logged in user.  Beyond that, it's meant to encapsulate the
 * notification framework in the case we want to change it out later.
 */
@Injectable({ providedIn: 'root' })
export class ToastNotificationService {
    constructor(private notificationService: NotificationService, private toastr: ToastrService) {}

    showMessage(messageType: MessageType, message: string): void {
        if (messageType === MessageType.SUCCESS) {
            this.toastr.success(message, '', {
                timeOut: 5000,
                positionClass: 'toast-bottom-right'
            });
        } else if (messageType === MessageType.WARN) {
            this.toastr.warning(message, '', {
                timeOut: 5000,
                positionClass: 'toast-bottom-right'
            });
        } else if (messageType === MessageType.ERROR) {
            this.toastr.error(message, '', {
                timeOut: 5000,
                positionClass: 'toast-bottom-right',
                enableHtml: true
            });
        } else {
            this.toastr.info(message, '', {
                timeOut: 5000,
                positionClass: 'toast-bottom-right'
            });
        }
    }

    showSuccessMessage(message: string): void {
        this.toastr.success(message, '', {
            timeOut: 5000,
            positionClass: 'toast-bottom-right'
        });
    }

    showErrorMessage(message: string): void {
        this.toastr.error(message, 'Error', {
            timeOut: 5000,
            positionClass: 'toast-bottom-right',
            enableHtml: true
        });
    }

    showWarnMessage(message: string): void {
        this.toastr.info(message, 'Warning', {
            timeOut: 5000,
            positionClass: 'toast-bottom-right'
        });
    }

    showDefaultMessage(message: string): void {
        this.toastr.info(message, '', {
            timeOut: 5000,
            positionClass: 'toast-bottom-right'
        });
    }
}
