import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { NsmBaseFieldComponent } from '../nsm-base-field.component';

@Component({
    selector: 'nsm-read-only-field',
    templateUrl: './nsm-read-only.component.html',
    styleUrls: ['../nsm-base-field.component.scss', './nsm-read-only.component.scss']
})
export class NsmReadOnlyComponent extends NsmBaseFieldComponent implements OnInit {
    @HostBinding('class') class: string = 'nsm-read-only-field';

    @Input()
    value: string;

    constructor() {
        super();
    }

    ngOnInit(): void {}
}
