import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['../landing-page-common.scss']
})
export class NotFoundComponent implements OnInit {
    constructor(private _location: Location) {}

    ngOnInit(): void {}

    returnToPreviousPage(): void {
        this._location.back();
    }
}
