<div class="nsm-landing-container">
    <div class="nsm-landing-form-wrapper">
        <div class="nsm-landing-form">
            <header class="nsm-landing-form-header">
                <a class="nsm-branding"><img src="/assets/nsm-logo.svg"/></a>
            </header>
            <div class="nsm-landing-form-content" *ngIf="{ isDoneLoading: isDoneLoading$ | async } as data">
                <div class="nsm-landing-sequence-message">
                    <div *ngIf="!user">
                        <div *ngIf="!hasLoggedIn()">
                            <app-nsm-loader *ngIf="!isRequestingUser" [inline]="true">Logging In</app-nsm-loader>
                        </div>
                        <div *ngIf="hasLoggedIn()">
                            <app-nsm-loader *ngIf="!isRequestingUser" [inline]="true"
                                >Validating Response</app-nsm-loader
                            >
                            <app-nsm-loader *ngIf="isRequestingUser" [inline]="true"
                                >Requesting User Profile</app-nsm-loader
                            >
                        </div>
                    </div>
                    <div *ngIf="user">
                        <app-nsm-loader [inline]="true">Loading {{ targetApplication }}</app-nsm-loader>
                    </div>
                </div>

                <footer class="nsm-landing-form-footer" *ngIf="user">
                    <div class="nsm-logged-in-user clearfix">
                        <span class="fas fa-user-circle"></span>
                        <span class="nsm-users-name"> {{ user.displayName }}</span>
                        <a (click)="logout()" class="nsm-button">Logout</a>
                    </div>
                </footer>
            </div>
        </div>
    </div>
</div>
