import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    FileSizePipe,
    PhonePipe,
    DmsLocalTimePipe,
    DmsFullDateNoTimePipe,
    FpDatePipe,
    TruncatePipe,
    AddressPipe,
    RemoveExtensionPipe,
    FpDateNoTimePipe,
    FpYesNoPipe
} from './pipes';
import { WorkOrderNumberDirective } from './directives/work-order-number.directive';

@NgModule({
    declarations: [
        RemoveExtensionPipe,
        PhonePipe,
        DmsLocalTimePipe,
        DmsFullDateNoTimePipe,
        FpDatePipe,
        FpDateNoTimePipe,
        TruncatePipe,
        AddressPipe,
        WorkOrderNumberDirective,
        FileSizePipe,
        FpYesNoPipe
    ],
    imports: [CommonModule],
    exports: [
        RemoveExtensionPipe,
        PhonePipe,
        DmsLocalTimePipe,
        DmsFullDateNoTimePipe,
        FpDatePipe,
        FpDateNoTimePipe,
        TruncatePipe,
        AddressPipe,
        WorkOrderNumberDirective,
        FileSizePipe,
        FpYesNoPipe
    ],
    providers: []
})
export class FpDomainModule {}
