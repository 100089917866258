import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpErrorHandler } from './error.handler.service';
//import { HttpErrorHandler } from '@app/core/services';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { RaEmployee } from '../models/ra.employee';

@Injectable({ providedIn: 'root' })
export class EmployeeService {
    handleError: any;
    private employee: RaEmployee = null;
    readonly employeeServiceUrl: string = `${environment.rehabEmployeeApi}`;

    constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('EmployeeService');
    }

    public getEmployee(firstName: string, lastName: string, emailAddress: string): Observable<RaEmployee> {
        if (this.employee) return of(this.employee);
        let params: HttpParams;
        if (emailAddress) {
            params = new HttpParams()
                .set('firstname', firstName)
                .set('lastname', lastName)
                .set('emailaddress', emailAddress);
        } else {
            params = new HttpParams().set('firstname', firstName).set('lastname', lastName);
        }
        const url = `${this.employeeServiceUrl}`;
        return this.http
            .get<RaEmployee>(url, { params })
            .pipe(
                map((response: Object) => {
                    this.employee = Object.assign(new RaEmployee(), response);
                    return this.employee;
                }),
                catchError(this.handleError('getEmployee', null))
            );
    }
}
