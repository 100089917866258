import { environment } from '../../../environments/environment';
import { ChromeRaLinkGroups, CsrAdminGroups, CsrUserGroups } from '../adGroups';
import { AdUser } from './adUser';
export class PortalUser implements AdUser {
    id: number;
    displayName: string;
    firstName: string;
    lastName: string;
    email: string;
    activeDirectoryId: string;
    groups: Array<string> = [];
    branches: string[] = [];

    isFundingSpecialist(): boolean {
        return this.groups.find((groupId: string) => groupId === environment.specialistsGroupId) != null;
    }

    isFundingDirector(): boolean {
        return this.groups.find((groupId: string) => groupId === environment.directorsGroupId) != null;
    }
    isCsrUser(): boolean {
        if (environment.development) return true;
        const allGroups = CsrUserGroups.concat(CsrAdminGroups);
        return this.groups.find((id: string) => allGroups.find(g => g === id)) != null;
    }
    isCsrAdmin(): boolean {
        if (environment.development) return true;
        return this.groups.find((id: string) => CsrAdminGroups.find(g => g === id)) != null;
    }
    isInGroup(groupId: string) {
        return this.groups.find(g => g === groupId) != null;
    }
    isChromeRaUser(): boolean {
        return this.groups.find((id: string) => ChromeRaLinkGroups.find(g => g === id)) != null;
    }

    getAdUsername(): string {
        if (this.email.includes('@')) return this.email.substr(0, this.email.indexOf('@'));
        return this.email;
    }
}
