import { NgModule } from '@angular/core';
import { AppMenuComponent } from './app-menu.component';
import { CommonModule } from '@angular/common';
import { PopupModule } from '@progress/kendo-angular-popup';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [AppMenuComponent],
    imports: [CommonModule, PopupModule, RouterModule],
    exports: [AppMenuComponent, CommonModule, PopupModule, RouterModule]
})
export class AppMenuModule {}
