<div class="content-wrapper" *ngIf="show$ | async as showMenu">
    <kendo-popup (click)="onHide()" *ngIf="showMenu" class="appMenu">
        <div class="content">
            <a routerLink="/" target="_blank"
                ><span class="icon fas fa-clipboard-list"></span><span>Funding Portal</span></a
            >
            <a routerLink="/dms" target="_blank"
                ><span class="icon fas fa-file-upload"></span><span>Document Management Service</span></a
            >
            <a routerLink="/upd" target="_blank"
                ><span class="fa-layers fa-fw">
                    <i class="icon fas fa-universal-access"></i>
                    <i class="icon icon-db fas fa-database"></i>
                </span>
                <span>Universal Payer Database</span></a
            >
        </div>
        <span style="text-align: center;">Build Version: {{ buildDetails$ | async }}</span>
    </kendo-popup>
</div>
