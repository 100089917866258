import { Component, OnInit, Input, ViewChild, ChangeDetectionStrategy, AfterViewInit } from '@angular/core';
import { DropDownListComponent, MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import { NsmBaseFieldComponent } from '../nsm-base-field.component';
import { FieldOption } from '@app/domain';
import { DocumentTypesFieldOption } from '@app/document-management-service/models/dms-models';

@Component({
    selector: 'dms-dropdown-field',
    templateUrl: './dms-dropdown-field.component.html',
    styleUrls: ['./dms-dropdown-field.component.scss', '../nsm-base-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DmsDropdownFieldComponent extends NsmBaseFieldComponent implements OnInit, AfterViewInit {
    @Input()
    multiselect: boolean;

    @Input()
    options: Array<DocumentTypesFieldOption>;

    @ViewChild('multiselectfield')
    multiselectField: MultiSelectComponent;

    @ViewChild('dropdownfield')
    dropdownField: DropDownListComponent;

    defaultItem: FieldOption;
    filteredOptions: Array<DocumentTypesFieldOption>;
    unfilteredOptions: Array<DocumentTypesFieldOption>;

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.unfilteredOptions = this.options;
    }

    ngAfterViewInit(): void {
        if (this.placeholder) {
            this.defaultItem = new FieldOption(this.placeholder, '');
        } else {
            this.defaultItem = new FieldOption('Select...', '');
        }
    }

    valueChange(e: any): void {}

    isItemSelected(itemText: string): boolean {
        const values = this.control.value;

        if (!values) {
            return false;
        }

        return values.some((item: FieldOption) => {
            return item.value.toString().toLowerCase() === itemText.toString().toLowerCase();
        });
    }

    handleFilter(value: string) {
        if (value.length > 0) {
            this.filteredOptions = this.options.filter(s => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1);
            this.options = this.filteredOptions;
        } else {
            this.options = this.unfilteredOptions;
        }
    }
}
