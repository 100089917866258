import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { FormControl, AbstractControl } from '@angular/forms';

@Component({
    selector: 'nsm-field-warning',
    templateUrl: './field-warning.component.html',
    styleUrls: ['./field-warning.component.scss']
})
export class NsmFieldWarningComponent {
    @HostBinding('class') class: string = 'nsm-field-warning';

    constructor() {}
}
