import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { AppComponent } from '@app/app.component';
import { CoreModule } from '@app/core/core.module';
import { GridModule } from '@progress/kendo-angular-grid';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { AppMenuModule } from './common/components/app-menu/appMenu.Module';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { PagerModule } from '@progress/kendo-angular-pager';
import { Globals } from './document-management-service/common/globals';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        CoreModule,
        NotificationModule,
        GridModule,
        LayoutModule,
        AppMenuModule,
        DropDownsModule,
        PagerModule,
        ToastrModule.forRoot()
    ],
    declarations: [AppComponent],
    providers: [Globals],
    bootstrap: [AppComponent]
})
export class AppModule {}
