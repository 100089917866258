export const fileTypeFromExt = (filename: string): string => {
    if (filename.toLocaleLowerCase().endsWith('xls') || filename.toLocaleLowerCase().endsWith('xlsx')) return 'Excel';

    if (filename.toLocaleLowerCase().endsWith('doc') || filename.toLocaleLowerCase().endsWith('docx')) return 'Word';

    if (filename.toLocaleLowerCase().endsWith('ppt') || filename.toLocaleLowerCase().endsWith('pptx'))
        return 'Powerpoint';

    if (filename.toLocaleLowerCase().endsWith('pdf')) return 'PDF/Acrobat';

    if (filename.toLocaleLowerCase().endsWith('tif') || filename.toLocaleLowerCase().endsWith('tiff')) return 'Tiff';

    if (filename.toLocaleLowerCase().endsWith('rtf')) return 'Rich Text';

    if (filename.toLocaleLowerCase().endsWith('txt')) return 'Text';

    return 'unknown';
};
