import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { FeatureOptionsService } from '@app/core/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class UpdUserGuard implements CanActivate {
    constructor(private router: Router, private featureOptionService: FeatureOptionsService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.featureOptionService.isUpdEnabled().pipe(
            map((enabled: boolean) => {
                if (!enabled) this.router.navigate(['/no-permissions']);

                return enabled;
            })
        );
    }
}
