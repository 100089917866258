import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { DateFormats } from '@app/work-order-queues/models';

@Pipe({
    name: 'dmsLocalTime'
})
export class DmsLocalTimePipe extends DatePipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (value) {
            const momentDate = moment(value);
            if (momentDate.isValid()) {
                const dateStr = momentDate.toString();
                return moment(`${dateStr}Z`).format(DateFormats.HH_MM_SS_SPACE);
            } else return '';
        }
        return '';
    }
}

@Pipe({
    name: 'dmsDateNoTime'
})
export class DmsFullDateNoTimePipe extends DatePipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (value) {
            const momentDate = moment(value);
            if (momentDate.isValid()) return momentDate.format(DateFormats.MMMM_DD_YYYY_SPACE);
            else return '';
        }
        return '';
    }
}

@Pipe({
    name: 'fpDate'
})
export class FpDatePipe extends DatePipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (value) {
            const momentDate = moment(value);
            if (momentDate.isValid()) return momentDate.format(DateFormats.MM_DD_YYYY_DASH);
            else return '';
        }
        return '';
    }
}

@Pipe({
    name: 'fpDateNoTime'
})
export class FpDateNoTimePipe extends DatePipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (value) {
            const momentDate = moment.utc(value);
            if (momentDate.isValid()) return momentDate.format(DateFormats.MM_DD_YYYY_DASH);
            else return '';
        }
        return '';
    }
}
