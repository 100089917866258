import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { AuthenticationService } from './authentication.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardWithForcedLoginService implements CanActivate {
    private isAuthenticated: boolean;

    constructor(private authService: AuthenticationService, private router: Router) {
        this.authService.isAuthenticated$.subscribe(i => (this.isAuthenticated = i));
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const hasTokens = this.authService.hasValidToken();

        if (!hasTokens) {
            this.authService.navigateToLoginPage(state.url);
        }

        return of(hasTokens);
    }
}
