import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AppMenuService {
    private showAppMenuEmitter$ = new Subject<boolean>();

    constructor() {}

    getMenuStateChange(): Subject<boolean> {
        return this.showAppMenuEmitter$;
    }

    showMenu(): void {
        this.showAppMenuEmitter$.next(true);
    }
    hideMenu(): void {
        this.showAppMenuEmitter$.next(false);
    }
}
