import { Component, OnInit, Input, HostBinding } from '@angular/core';

@Component({
    selector: 'app-nsm-loader',
    templateUrl: './nsm-loader.component.html',
    styleUrls: ['./nsm-loader.component.scss']
})
export class NsmLoaderComponent implements OnInit {
    @HostBinding('class') class: string = 'nsm-loader-component';

    @Input() loading: boolean = true;
    @Input() inline: boolean;

    constructor() {}

    ngOnInit(): void {}
}
