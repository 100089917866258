import { QueueType } from '@app/core/models';

export class FollowUpRequest {
    followUpDate: string;
    note: string;
    queueType: QueueType;
    workItemId: number;
}

export class NoteUpdateRequest {
    workOrderId: string;
    queue: string;
    note: string;
    noteCreatedOn: Date;
    enteredBy: string;
    title: string;
    description: string;
    followUpOn: string;
    reasonCodeText: string;
}
