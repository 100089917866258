/**
 * Common models used across each module
 */

export class FieldOption {
    constructor(public text: string, public value: string | number | boolean) {}
}

export class CheckboxFieldOption extends FieldOption {
    constructor(public text: string, public value: string | number | boolean, public selected: boolean) {
        super(text, value);
    }
}

export class PickListFieldOption extends FieldOption {
    constructor(public text: string, public value: string | number, public sortValue: string | number) {
        super(text, value);
    }
}
