<div class="nsm-landing-container">
    <div class="nsm-landing-form-wrapper">
        <div class="nsm-landing-form">
            <header class="nsm-landing-form-header">
                <a class="nsm-branding"><img src="/assets/nsm-logo.svg"/></a>
            </header>
            <div class="nsm-landing-form-content">
                <div class="nsm-landing-sequence-message">
                    <app-nsm-loader [loading]="false" [inline]="true">
                        Looks like you're looking for something that doesn't exist.</app-nsm-loader
                    >
                </div>
            </div>
            <footer class="nsm-landing-form-footer">
                <div class="nsm-logged-in-user clearfix">
                    <a (click)="returnToPreviousPage()" class="nsm-button">Go Back</a>
                </div>
            </footer>
        </div>
    </div>
</div>
