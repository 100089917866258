import { NgModule } from '@angular/core';
import { NsmTextFieldComponent } from './fields/text-field/nsm-text-field.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DateInputsModule, DatePickerComponent } from '@progress/kendo-angular-dateinputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { GridModule } from '@progress/kendo-angular-grid';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { FileSelectModule, UploadModule } from '@progress/kendo-angular-upload';
import { NsmRequiredMarkerComponent } from './common/required-marker/required-marker.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { NsmDropdownFieldComponent } from './fields/dropdown-field/nsm-dropdown-field.component';
import { NsmDateFieldComponent } from './fields/date-field/nsm-date-field.component';
import { NsmReadOnlyComponent } from './fields/read-only/nsm-read-only.component';
import { NsmLoaderComponent } from './common/loader/nsm-loader.component';
import { LayoutModule, StepperModule } from '@progress/kendo-angular-layout';
import { NsmPickListComponent } from './fields/pick-list/nsm-pick-list.component';
import { NsmRadioGroupComponent } from './fields/radio-group/nsm-radio-group.component';
import { NsmFieldErrorComponent } from './common/field-error/field-error.component';
import { NsmFieldWarningComponent } from './common/field-warning/field-warning.component';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { NsmCheckboxListGroupComponent } from './fields/checkbox-list/nsm-checkbox-list.component';
import { SortableModule } from '@progress/kendo-angular-sortable';
import { PopupModule } from '@progress/kendo-angular-popup';
import { NsmMaskedTextFieldComponent } from './fields/masked-text-field/nsm-masked-text-field.component';
import { NsmFileUploadFieldComponent } from './fields/file-upload/nsm-file-upload.component';
import { NsmCalloutComponent } from './common/callout/callout.component';

import { NsmCurrencyFieldComponent } from './fields/currency-field/nsm-currency-field.component';
import { NsmNumberFieldComponent } from './fields/number-field/nsm-number-field.component';
import { DmsDropdownFieldComponent } from './fields/dms-dropdown-field/dms-dropdown-field.component';
@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        DropDownsModule,
        InputsModule,
        DateInputsModule,
        ButtonsModule,
        GridModule,
        DialogModule,
        UploadModule,
        TooltipModule,
        TreeViewModule,
        StepperModule,
        FileSelectModule
    ],
    declarations: [
        NsmReadOnlyComponent,
        NsmRequiredMarkerComponent,
        NsmCalloutComponent,
        NsmFieldErrorComponent,
        NsmFileUploadFieldComponent,
        NsmFieldWarningComponent,
        NsmTextFieldComponent,
        NsmDropdownFieldComponent,
        NsmDateFieldComponent,
        NsmPickListComponent,
        NsmRadioGroupComponent,
        NsmLoaderComponent,
        NsmCheckboxListGroupComponent,
        NsmCurrencyFieldComponent,
        NsmNumberFieldComponent,
        NsmMaskedTextFieldComponent,
        DmsDropdownFieldComponent
    ],
    exports: [
        // General
        NsmLoaderComponent,
        NsmCalloutComponent,
        // Fields Based on Kendo
        NsmReadOnlyComponent,
        NsmRequiredMarkerComponent,
        NsmFieldErrorComponent,
        NsmFileUploadFieldComponent,
        NsmFieldWarningComponent,
        NsmTextFieldComponent,
        NsmDropdownFieldComponent,
        NsmDateFieldComponent,
        NsmPickListComponent,
        NsmRadioGroupComponent,
        NsmCheckboxListGroupComponent,
        NsmCurrencyFieldComponent,
        NsmNumberFieldComponent,
        NsmMaskedTextFieldComponent,
        DmsDropdownFieldComponent,
        // Kendo
        DropDownsModule,
        InputsModule,
        DateInputsModule,
        ButtonsModule,
        GridModule,
        DialogModule,
        UploadModule,
        TooltipModule,
        LayoutModule,
        FileSelectModule,
        TreeViewModule,
        StepperModule,
        SortableModule,
        PopupModule,
        DatePickerComponent
    ]
})
export class NsmKendoSharedModule {}
