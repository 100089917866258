import { Component, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
    selector: 'app-access-denied',
    templateUrl: './access-denied.component.html',
    styleUrls: ['../landing-page-common.scss']
})
export class AccessDeniedComponent implements OnInit {
    constructor(private oAuthService: OAuthService) {}

    ngOnInit() {}

    logout(): void {
        this.oAuthService.logOut();
    }
}
