import { Component, OnInit, Input, ViewChild, ChangeDetectionStrategy, AfterViewInit } from '@angular/core';
import { DropDownListComponent, MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import { NsmBaseFieldComponent } from '../nsm-base-field.component';
import { FieldOption } from '@app/domain';

@Component({
    selector: 'nsm-dropdown-field',
    templateUrl: './nsm-dropdown-field.component.html',
    styleUrls: ['../nsm-base-field.component.scss', './nsm-dropdown-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NsmDropdownFieldComponent extends NsmBaseFieldComponent implements OnInit, AfterViewInit {
    @Input()
    multiselect: boolean;

    @Input()
    options: Array<FieldOption>;

    @ViewChild('multiselectfield')
    multiselectField: MultiSelectComponent;

    @ViewChild('dropdownfield')
    dropdownField: DropDownListComponent;

    defaultItem: FieldOption;

    constructor() {
        super();
    }

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        if (this.placeholder) {
            this.defaultItem = new FieldOption(this.placeholder, '');
        } else {
            this.defaultItem = new FieldOption('Select...', '');
        }
    }

    valueChange(e: any): void {}

    isItemSelected(itemText: string): boolean {
        const values = this.control.value;

        if (!values) {
            return false;
        }

        return values.some((item: FieldOption) => {
            return item.value.toString().toLowerCase() === itemText.toString().toLowerCase();
        });
    }
}
