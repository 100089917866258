import { QueueType } from '@app/core/models';
import * as moment from 'moment';
import * as momentbusiness from 'moment-business';
import { WorkOrder } from '.';
import { Evaluation } from './work.order';

/**
 * The WorkItem represents a record in the FP tables.  It's a simple object
 * that allows us to make another call to get more indepth details about
 * the work order.
 */

export class WorkItem {
    attempts: number;
    branch: string;
    createdOn: Date;
    description: string;
    label: string;
    taskType: string;
    dueOn: Date;
    id: number;
    priority: number;
    queue: string;
    updatedOn: Date;
    taskedOn: Date;
    workOrderNumber: string;
    workLocation: string;
    constructor() {}

    isPastDue(): boolean {
        const todayMoment = moment(new Date()).startOf('day');
        const dueOnMoment = moment(this.dueOn).startOf('day');
        return dueOnMoment.isBefore(todayMoment);
    }

    isFutureTask(): boolean {
        const dueOnMoment = moment(this.dueOn).startOf('day');
        let todayMoment = moment(new Date()).startOf('day');
        if (this.isExtendedQueue()) {
            // For these queueus, they have a 2 day grace period.
            // Which counts the day it was created + the following day
            // to complete.
            todayMoment = momentbusiness.addWeekDays(todayMoment, 1);
        }
        return dueOnMoment.isAfter(todayMoment);
    }

    isDueToday(): boolean {
        return !this.isFutureTask() && !this.isPastDue();
    }

    getDaysPastDue(): number {
        return this.getDaysBetween(this.dueOn, new Date());        
    }

    getDaysSinceCreation(): number {
        return this.getDaysBetween(this.createdOn, new Date());        
    }

    getDaysInReport(): number {
        return this.getDaysBetween(this.taskedOn, new Date());        
    }

    private isExtendedQueue(): boolean {
        return (
            this.queue === QueueType.CODING_AND_PRICING ||
            this.queue === QueueType.READY_TO_SUBMIT ||
            this.queue === QueueType.PPQA
        );
    }

    private getDaysBetween(dateA: Date, dateB: Date): number {
        
        const a = moment(dateA).format('YYYY-MM-DD')
        const b = moment(dateB).format('YYYY-MM-DD')
        const day = moment(b).diff(moment(a), 'days');
        return day;
    }
}
