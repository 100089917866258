import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { PortalUser } from '@app/core/models';
import { UserService } from '@app/core/services';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable()
export class FundingSpecialistGuard implements CanActivate {
    constructor(private router: Router, private activatedRoute: ActivatedRoute, private userService: UserService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.userService.getUserFromOauth().pipe(
            switchMap((user: PortalUser) => {
                if (!user) {
                    this.router.navigate(['/access-denied']);
                    return of(false);
                }

                if (user.isFundingSpecialist()) {
                    return of(true);
                }

                if (user.isFundingDirector()) {
                    this.router.navigate(['/woq/admin/funding']);
                    return of(false);
                }

                this.router.navigate(['/no-permissions']);
                return of(false);
            })
        );
    }
}
