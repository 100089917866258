<div class="field-control-wrapper" [class.has-errors]="shouldShowErrors()">
    <label [attr.for]="id" class="field-label"
        >{{ label }}
        <nsm-required-marker *ngIf="required"> </nsm-required-marker>
    </label>
    <div class="field-control-group" [class.has-icon]="iconClass != null">
        <span *ngIf="iconClass" class="icon {{ iconClass }}"></span>

        <kendo-numerictextbox
            class="field-control"
            [formControl]="control"
            [format]="'#'"
            [decimals]="0"
            [min]="min"
            [max]="max"
        ></kendo-numerictextbox>

        <span class="field-errors" *ngIf="shouldShowErrors()">
            <ng-content select="nsm-field-error"></ng-content>
        </span>
        <span class="field-warnings">
            <ng-content select="nsm-field-warning"></ng-content>
        </span>
    </div>
</div>
