import * as moment from 'moment';
import { DateFormats } from '@app/work-order-queues/models';

export class RehabNote {
    workOrderId: string;
    category: string;
    createdDate: Date;
    note: string;
    isDeleted: boolean;
    enteredBy: string;
    hasBeenEmailed: boolean;

    getNoteDate(): string {
        return moment(this.createdDate).format(DateFormats.MM_DD_YYYY_DASH);
    }
}
