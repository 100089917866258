import { QueueType } from '@app/core/models';

export class SignalrNotification {
    id: number;
    workOrderNumber: string;
    queue: QueueType;
    eventType: SignalrEventType;
    entity: string;
    assignedTo: string;
}

export enum SignalrEventType {
    CREATED = 'CREATED',
    DELETED = 'DELETED',
    UPDATED = 'UPDATED'
}

export enum SignalrEntityType {
    WORK_ORDER = 'WorkOrder',
    WORK_ITEM = 'WorkItem'
}
