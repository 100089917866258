import { Injectable } from '@angular/core';
import { Document, WorkOrderDocument } from '@app/document-management-service/models';
import { FieldOption } from '@app/domain';

@Injectable()
export class Globals {
    data: Document[] = [];
    WorkOrderData: WorkOrderDocument[] = [];
    to: FieldOption[];
    from: FieldOption[];
    workOrderId: string;
    scannedDocsId: string;
    docType: string;
    branchId: number = 0;
    branchList: number[];
    workOrderSearchCaller: string;
}
