export namespace RehabApi {
    export interface ICollectionAttempts {
        type: string;
        attempts: number;
        date: string;
    }

    export class IExpectedReimbursementLineItemNote {
        type: EstimatedReimbursementDetailNoteType;
        note: string;
    }

    export enum EstimatedReimbursementDetailNoteType {
        Information,
        Notification,
        Warning,
        MUE,
        PTP,
        User,
        Discount
    }
    export class IDiscount {
        discountType: DiscountType;
        discount: number;
        discountMethod: DiscountMethod;
    }
    export enum DiscountMethod {
        UCR = 'UCR',
        MSRP = 'MSRP',
        HARDSHIP = 'HARDSHIP'
    }
    export enum DiscountType {
        Override,
        Percentage
    }
    export interface IHcpcLineItem {
        hcpcCode1: string;
        hcpcCode2: string;
        hcpcCode3: string;
        ordinal: number;
        ucrAmount: number;
        msrpAmount: number;
        quantity: number;

        pricingModifier: string;
        infoModifier: string;
        vendorDiscount1: number;
        vendorDiscount2: number;
        bundleWith: string;
        includeWith: string;
        upgradeFrom: string;
        downcodeTo: string;
        authorizationStatus: string;
        perItemConversionFactor: number;
        discount: IDiscount;
        isTaxable: boolean;
    }
    export interface IExpectedReimbursementDetails {
        ordinal: number;
        payerOrdinal: number;
        payerCode: string;
        payerName: string;
        payerPlanName: string;
        hcpc: string;
        hcpc2: string;
        hcpc3: string;
        allowable: number;
        adjustedAllowable: number;
        billedChargesMethod: string;
        billable: number;
        copay: number;
        deductible: number;
        upgrades: number;
        maxOutOfPocketOverage: number;
        maxDurableMedicalEquipmentOverage: number;
        discount: number;
        benefitPercent: number;
        coInsPercent: number;
        insuranceRatio: string;
        benefitAmount: number;
        coInsAmount: number;
        difference: number;
        lesserOf: number;
        payerDue: number;
        clientResponsibility: number;
        payerPays: number;
        clientPays: number;
        cob: number;
        writeOff: number;
        hardship: number;
        authorization: string;
        method: string;
        modifier: string;
        term: number;
        isGenericFeeSchedule: true;
        isTaxable: true;
        isSetAllowable: true;
        billingQuantity: number;
        billingUnitOfMeasure: string;
        notes: IExpectedReimbursementLineItemNote[];
        input: IHcpcLineItem;
    }

    export interface IWorkOrderDetails {
        cost: number;
        quantity: number;
        unit: string;
        msrp: number;
        lineOrdinal: number;
        itemPrice: number;
        vendorPartNumber: string;
        vendorName: string;
        itemDesc: string;
        expectedReimbursementDetails: IExpectedReimbursementDetails[];
    }

    export interface IWorkOrderDetailSave extends IWorkOrderDetails {
        id: string;
        workOrderId: string;
        lineTotal: number;
        lineCost: number;
        model: string;
        perItemConversionFactor: string;
        vendorAccountNumber: string;
        vendorDiscount1: number;
        vendorDiscount2: number;
        hcpcsCode1: string;
        hcpcsCode2: string;
        hcpcsCode3: string;
        hcpcsCode4: string;
        productNumber: string;
        isLabor: boolean;
        internalNote: string;
        externalNote: string;
        userId: string;
        branchId: number;
    }
    export interface IWorkOrderLaborDetailSave {
        note: string;
        reasons: string[];
        isBillable: boolean;
        productNumber: string;
        units: number; // total service units billed and unbilled
        quantity: number; // billed service units
        rate: number;
    }

    export interface IWorkOrderLaborDetails {
        descFull: string;
        descMod: string;
        descShort: string;
        isBillable: boolean;
        chairType: string;
        group: number;
        jobCode: string;
        laborRateId: number;
        rate: number;
        reason: number;
        reasonList: string[];
        recType: number;
        units: number; // total service units billed and unbilled
        quantity: number; // billed units
        time: number;
        unitId: number;
    }

    export interface IWorkOrderNote {
        noteDate: string;
        text: string;
        category: string;
        modifier: string;
        enteredBy: string;
    }

    export interface IPatient {
        sex: string;
        ssn: string;
        homePhone: string;
        cellPhone: string;
        workPhone: string;
        address: string;
        city: string;
        state: string;
        zip: string;
        zone: string;
        diagCodes: string[];
        dateOfBirth: string;
        personId: string;
        firstName: string;
        middleName: string;
        lastName: string;
        email: string;
        phone: string;
        fullName: string;
    }

    export interface ISalesOrder {
        branchReceived: string;
        finalEstimatedShipDate: string;
        finalEstimateReceived: string;
    }

    export interface IRequiredDocument {
        type: string;
        completed: true;
        completedOn: string;
        requiredAt: number;
        docDate: string;
        docFileName: string;
        user: string;
        label: string;
    }
    export interface IAuthorizationReceived {
        type: string;
        date: string;
    }
    export interface IPayer {
        payerCode: string;
        name: string;
        phone: string;
        submitted: true;
        submittedOn: string;
        requiredDocuments: IRequiredDocument[];
        authorizationReceived: IAuthorizationReceived;
        authorizationExpiredDate: string;
        hasAuthorizationExpired: true;
        authNumbers: string;
        responsibleParty: IPatient;
        planNumber: string;
        groupNumber: string;
        npi: string;
        employer: string;
        authorizationDeferredDate: string;
        resubmittedOn: string;
        payerSequence: number;
    }

    export interface IOrderType {
        orderTypeId: number;
        description: string;
    }

    export interface IPerson {
        personId: string;
        firstName: string;
        middleName: string;
        lastName: string;
        email: string;
        phone: string;
        fullName: string;
    }

    export interface IFacility {
        facilityId: string;
        name: string;
        address: string;
        city: string;
        state: string;
        zip: string;
        npi: string;
        phone: string;
        email: string;
    }
    export interface ICustomerType {
        customerTypeId: number;
        description: string;
    }
    export interface IDelivery {
        deliveredByPrimary: IPerson;
        deliverySchedulingAttempts: number;
        lastDeliverySchedulingAttempt: string;
        scheduledOn: string;
        deliveredOn: string;
        deliveryZone: string;
        deliveryCity: string;
        deliveryZipCode: string;
        ticketScanned: true;
        ticketScannedOn: string;
        verified: true;
        requirementsCode: string;
        actualAssemblyDate: string;
        isAssemblyComplete: true;
    }

    export interface IEvaluation {
        evaluationCity: string;
        evaluationZipCode: string;
        evaluationPerson: string;
        scheduledPerson: string;
        scheduledOn: string;
        evaluatedOn: string;
        lastEvaluationSchedulingAttempt: string;
        evaluationSchedulingAttempts: number;
        evaluationZone: string;
    }
    export interface IWorkOrder {
        keyAcctManagerName: string;
        workOrderId: string;
        woCategory: number;
        collectionAttempts: ICollectionAttempts[];

        serialNumber: string;
        workOrderDetails: IWorkOrderDetails[];
        notes: IWorkOrderNote[];
        responsibleParties: IPatient[];
        salesOrder: ISalesOrder;
        client: IPatient;
        payers: IPayer[];
        orderType: IOrderType;

        subOrderType: IOrderType;
        physician: IPerson;
        therapist: IPerson;
        rts: IPerson;
        assignedFundingSpecialist: IPerson;
        branch: string;
        facility: IFacility;
        customerType: ICustomerType;
        createdOn: string;
        scheduledDeliveryOn: string;
        creditExceptionDate: string;
        creditExceptionDelivery: string;
        finalFundingApprovedDate: string;
        finalRulesCompDate: string;
        finalSubmitForFundingDate: string;
        readyToProcessDate: string;
        isOnHold: true;
        isFpnaComplete: true;
        isCancelled: true;
        fpnaCompleteDate: string;
        deliveryVerifiedOn: string;
        deliveredOn: string;
        totalAmount: number;
        secondLevelReviewState: number;
        delivery: IDelivery;
        evaluation: IEvaluation;
        isAllPurchaseOrdersReceived: true;
        isAllPurchaseOrdersIssued: true;
        allPurchaseOrdersIssued: string;
        isInShop: true;
        solutionBuilderCompleteDate: string;
        primaryInsurance: string;
        isBenefitsChecked: true;
        isFaceToFaceScanned: true;
        isCreditException: true;
        jointCommissionFollowUp: string;
        shipping: number;
        tax: number;
    }

    export interface IPayerDetail {
        copay: number;
        payerCode: string;
        payerOrder: number;
        planType: string;
        planBeginDate: string;
        planExpDate: string;
        deductibleAnnual: number;
        deductibleMetToDate: number;
        deductibleRemaining: number;
        nsMinNetwork: true;
        maxDMEbenefitAnnual: number;
        maxDMEbenefitMet: number;
        maxDMEbenefitRmng: number;
        maxDMELifetimeAnnual: number;
        maxDMELifetimeMet: number;
        maxDMELifetimeRmng: number;
        inNetworkBenefit: number;
        inNetworkCoIns: number;
        inNetworkDedctblFmlyAnnual: number;
        inNetworkDedctblFmlyMet: number;
        inNetworkDedctblFmlyRmng: number;
        inNetworkDedctblIndvdlAnnual: number;
        inNetworkDeductIndvdlMet: number;
        inNetworkDeductIndvdlRmng: number;
        inNetworkOOPMaxFmlyAnnual: number;
        inNetworkOOPMaxFmlyMet: number;
        inNetworkOOPMaxFmlyRmng: number;
        inNetworkOOPMaxIndvdlAnnual: number;
        inNetworkOOPMaxIndvdlMet: number;
        inNetworkOOPMaxIndvdlRmng: number;
        outNetworkBenefit: number;
        outNetworkCoIns: number;
        outNetworkDedctblFmlyAnnual: number;
        outNetworkDedctblFmlyMet: number;
        outNetworkDedctblFmlyRmng: number;
        outNetworkDedctblIndvdlAnnual: number;
        outNetworkDeductIndvdlMet: number;
        outNetworkDeductIndvdlRmng: number;
        outNetworkOOPMaxFmlyAnnual: number;
        outNetworkOOPMaxFmlyMet: number;
        outNetworkOOPMaxFmlyRmng: number;
        outNetworkOOPMaxIndvdlAnnual: number;
        outNetworkOOPMaxIndvdlMet: number;
        outNetworkOOPMaxIndvdlRmng: number;
    }
}
