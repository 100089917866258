import { Component, OnInit } from '@angular/core';
import { UserService } from '@app/core';
import { NsmAppRouterService } from '@app/core/services';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
    selector: 'app-no-permissions',
    templateUrl: './no-permissions.component.html',
    styleUrls: ['../landing-page-common.scss']
})
export class NoPermissionsComponent implements OnInit {
    constructor(
        private oAuthService: OAuthService,
        private appRouterSerivce: NsmAppRouterService,
        private userService: UserService
    ) {}

    ngOnInit() {}

    logout(): void {
        this.oAuthService.logOut();
    }

    goHome(): void {
        const user = this.userService.getUser();
        if (user.isFundingDirector()) {
            this.appRouterSerivce.routeToFundingPortalAdministration();
        } else if (user.isFundingSpecialist()) {
            this.appRouterSerivce.routeToFundingPortal();
        } else {
            // We only have two roles.
        }
    }
}
