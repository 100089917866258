<div class="nsm-field-control-wrapper" [class.has-errors]="shouldShowErrors()">
    <label [attr.for]="id" class="nsm-field-label"
        >{{ label }} <nsm-required-marker *ngIf="required"> </nsm-required-marker>
    </label>
    <div class="nsm-field-control-group" [class.has-icon]="iconClass != null">
        <span *ngIf="iconClass" class="icon {{ iconClass }}"></span>
        <input
            *ngIf="!multiline"
            type="text"
            kendoTextBox
            class="nsm-field-control"
            [formControl]="control"
            [placeholder]="placeholder"
            [maxLength]="maxLength"
        />
        <textarea
            *ngIf="multiline"
            class="nsm-field-control nsm-field-control-textarea"
            [formControl]="control"
            [placeholder]="placeholder"
            kendoTextArea
        ></textarea>
        <span class="field-errors" *ngIf="shouldShowErrors()">
            <ng-content select="nsm-field-error"></ng-content>
        </span>
        <span class="field-warnings">
            <ng-content select="nsm-field-warning"></ng-content>
        </span>
    </div>
</div>
