import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['../landing-page-common.scss']
})
export class LogoutComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
