import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { UpdUserGuard } from './helpers/guards';

import { AuthGuardWithForcedLoginService } from './authentication/auth-guard-with-forced-login.service';
import { LogoutComponent } from './landing/logout/logout.component';
import { LoginComponent, NoPermissionsComponent, AccessDeniedComponent } from '@app/core/landing';
import { AdminGuard } from './helpers/guards/admin.guard.service';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'prefix',
        redirectTo: 'login'
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'funding',
        redirectTo: 'woq'
    },
    {
        path: 'woq',
        loadChildren: () => import('../work-order-queues/workOrderQueues.module').then(m => m.WorkOrderQueuesModule),
        canActivate: [AuthGuardWithForcedLoginService]
    },
    {
        path: 'upd',
        loadChildren: () => import('../universal-payers-database/upd.module').then(m => m.UpdModule),
        canActivate: [AuthGuardWithForcedLoginService, UpdUserGuard]
    },
    {
        path: 'dms',
        loadChildren: () => import('../document-management-service/dms.module').then(m => m.DmsModule),
        canActivate: [AuthGuardWithForcedLoginService]
    },
    {
        path: 'er',
        loadChildren: () => import('../expected-reimbursement/er.module').then(m => m.ExpectedReimbursementModule),
        canActivate: [AuthGuardWithForcedLoginService]
    },
    {
        path: 'access-denied',
        component: AccessDeniedComponent
    },
    {
        path: 'no-permissions',
        component: NoPermissionsComponent
    },
    {
        path: 'logout',
        component: LogoutComponent
    },
    {
        path: '**',
        redirectTo: ''
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            onSameUrlNavigation: 'reload',

            relativeLinkResolution: 'legacy'
        })
    ],
    exports: [RouterModule]
})
export class CoreRoutingModule {}
