<div class="nsm-field-control-wrapper" [class.has-errors]="shouldShowErrors()">
    <label [attr.for]="id" class="nsm-field-label"
        >{{ label }} <nsm-required-marker *ngIf="required"> </nsm-required-marker>
    </label>
    <div class="nsm-field-control-group" [class.has-icon]="iconClass != null">
        <span *ngIf="iconClass" class="icon {{ iconClass }}"></span>
        <kendo-maskedtextbox class="field-control" [maskOnFocus]="false" [mask]="mask" [formControl]="control">
        </kendo-maskedtextbox>
        <span class="field-errors" *ngIf="shouldShowErrors()">
            <ng-content select="[errors]"></ng-content>
        </span>
    </div>
</div>
