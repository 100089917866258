import { Component, OnInit, Input } from '@angular/core';
import { NsmBaseFieldComponent } from '../nsm-base-field.component';

@Component({
    selector: 'nsm-date-field',
    templateUrl: './nsm-date-field.component.html',
    styleUrls: ['../nsm-base-field.component.scss', './nsm-date-field.component.scss']
})
export class NsmDateFieldComponent extends NsmBaseFieldComponent implements OnInit {
    @Input()
    max: Date;

    @Input()
    min: Date;

    @Input()
    format: string;

    @Input()
    formatPlaceholder: string;

    constructor() {
        super();
    }

    ngOnInit(): void {}
}
