import { environment } from './environment';
import { AuthConfig } from 'angular-oauth2-oidc';

export const authConfig: AuthConfig = {
    issuer: 'https://login.microsoftonline.com/ed907452-f178-4336-8e28-497e69d8aba4/v2.0',
    clientId: environment.azureAdClientId, // Solution Builder
    responseType: 'code',
    redirectUri: window.location.origin + '/index.html',
    logoutUrl: 'https://login.windows.net/ed907452-f178-4336-8e28-497e69d8aba4/oauth2/logout',
    silentRefreshRedirectUri: window.location.origin + '/assets/silent-refresh.html',
    scope: 'openid profile email', // Ask offline_access to support refresh token refreshes
    timeoutFactor: 0.75, // For faster testing
    sessionChecksEnabled: false,
    showDebugInformation: true, // Also requires enabling "Verbose" level in devtools
    clearHashAfterLogin: false, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
    nonceStateSeparator: 'semicolon', // Real semicolon gets mangled by IdentityServer's URI encoding
    strictDiscoveryDocumentValidation: false, //https://dev.to/yuriburger/azure-active-directory-b2c-with-pkce-for-your-angular-app-1dcg#the-twist-and-tweaks-with-azure-ad-b2c
    postLogoutRedirectUri: window.location.origin + '/logout'
    //oidc: false // https://manfredsteyer.github.io/angular-oauth2-oidc/docs/injectables/OAuthService.html#loadUserProfile
};
