import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { NsmBaseFieldComponent } from '../nsm-base-field.component';

@Component({
    selector: 'nsm-number-field',
    templateUrl: './nsm-number-field.component.html',
    styleUrls: ['./nsm-number-field.component.scss']
})
export class NsmNumberFieldComponent extends NsmBaseFieldComponent implements OnInit {
    @HostBinding('class') class: string = 'nsm-number-field';

    @Input()
    min: number = 0;

    @Input()
    max: number = 0;

    constructor() {
        super();
    }

    ngOnInit(): void {}
}
