import { FilterType, WorkItemPropertyNames } from '@app/work-order-queues/models';

export class FilterSelectConfig {
    properties: Array<WorkItemPropertyNames>;
    title: string;
    type: FilterType;
    sort: SortByEnum;

    // Uses values from this array
    // instead of inspecting the Task.
    useValues: Array<FilterSelectionOption> = [];
}

export class FilterSelectionOption {
    text: string;
    value: string;
}

export enum SortByEnum {
    IGNORE,
    DATE,
    TYPE,
}
