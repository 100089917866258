import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'removeExtension'
})
export class RemoveExtensionPipe implements PipeTransform {
    transform(value: string): any {
        if (value) {
            return value.substring(0, value.lastIndexOf('.'));
        }
        return value;
    }
}
