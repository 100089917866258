export enum QueueType {
    //FUNDING QUEUES
    CODING_AND_PRICING = 'codingAndPricing',
    DOCUMENT_REQUIREMENTS = 'docsRequired',
    DELIVERY_VERIFICATION = 'deliveryVerification',
    MIR = 'mir',
    PENDING = 'pendingAuth',
    PPQA = 'ppqa',
    READY_TO_SUBMIT = 'readyToSubmit',
    ACTION = 'actionRequired',

    //CSR QUEUES
    DELIVERY_POSTING = 'deliveryPosting',
    DELIVERY_SCHEDULING = 'deliveryScheduling',
    PRE_SCHEDULING = 'preScheduling',
    EXPIRED_EVAL = 'expiredEvaluation',
    EVAL_SCHEDULING = 'evaluationScheduling',
    PENDING_SB = 'pendingSolutionBuilder',
    PREVENTATIVE_MAINT = 'preventativeMaintenance',
    JOINT_COMMISSION = 'jointCommission'
}

export enum TaskType {
    //CSR QUEUES
    DELIVERY_SCHEDULING = 'DELIVERY_SCHEDULING',
    PRE_SCHEDULING = 'PRE_SCHEDULING'
}

export enum QueueTypeNames {
    CODING_AND_PRICING = 'Coding and Pricing',
    DOCUMENT_REQUIREMENTS = 'Document Requirements',
    DELIVERY_VERIFICATION = 'Delivery Verification',
    MIR = 'MIR',
    PENDING = 'Pending Authorization',
    PPQA = 'PPQA',
    READY_TO_SUBMIT = 'Ready to Submit',
    ACTION = 'Action Required'
}

export enum QueueListType {
    FS = 'fs',
    CSR = 'csr'
}
