<div class="nsm-field-control-wrapper" [class.has-errors]="shouldShowErrors()">
    <label [attr.for]="id" class="nsm-field-label"
        >{{ label }} <nsm-required-marker [control]="control"> </nsm-required-marker>
    </label>
    <div class="nsm-field-control-group" [class.has-icon]="iconClass != null">
        <input type="hidden" [formControl]="control" />
        <div class="grid-x grid-padding-x">
            <div class="large-6 cell">
                <label class="nsm-field-label">Available</label>
                <div class="nsm-pick-list-overflow-wrapper">
                    <ul class="nsm-options-list">
                        <li *ngFor="let item of available$ | async" class="nsm-options-list-item">
                            <a (click)="addItem(item)" class="nsm-options-list-item-element">{{ item.text }}</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="large-6 cell">
                <label class="nsm-field-label">Selected</label>
                <div class="nsm-pick-list-overflow-wrapper">
                    <ul class="nsm-options-list">
                        <li *ngFor="let item of control.value" class="nsm-options-list-item">
                            <a (click)="removeItem(item)" class="nsm-options-list-item-element">{{ item.text }}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
