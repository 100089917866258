import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { NsmBaseFieldComponent } from '../nsm-base-field.component';
import { FieldOption } from '@app/domain';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'nsm-checkbox-list',
    templateUrl: './nsm-checkbox-list.component.html',
    styleUrls: ['../nsm-base-field.component.scss', './nsm-checkbox-list.component.scss']
})
export class NsmCheckboxListGroupComponent extends NsmBaseFieldComponent implements OnInit {
    @HostBinding('class') class: string = 'nsm-checkbox-list';

    @Input() expandable: boolean = false;
    @Input() set expandedDefault(val: boolean) {
        this.expanded = val;
    }
    expanded: boolean = true;

    @Input()
    group: FormGroup;

    @Input()
    arrayName: string;

    _options: Array<FieldOption>;
    @Input('options') set options(val: FieldOption[]) {
        this._options = val;
        this.cleanAndCreateCheckboxes();
    }

    @Input()
    optionsShown: number = 5;

    isVisible: boolean = false;

    constructor() {
        super();
    }

    ngOnInit(): void {}

    getAvailableControls(): FormControl[] {
        return this.group ? ((<FormArray>this.group.get(this.arrayName)).controls as FormControl[]) : [];
    }

    private cleanAndCreateCheckboxes() {
        const array = <FormArray>this.group.get(this.arrayName);
        if (array) {
            array.clear();
            this._options.forEach(() => array.push(new FormControl(false)));
        }
    }

    toggleExpanded(): void {
        this.expanded = !this.expanded;
    }
}
