import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/core/authentication';
import { PortalUser } from '@app/core/models';
import { NsmAppRouterService, UserService } from '@app/core/services';
import { untilDestroyed } from '@app/core/utilities';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable, of } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['../landing-page-common.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
    user: PortalUser;
    isRequestingUser: boolean;

    isDoneLoading$: Observable<boolean>;

    targetApplication: string = 'Funding Portal';

    constructor(
        private authService: AuthenticationService,
        private userService: UserService,
        private oauthService: OAuthService,
        private appRouterService: NsmAppRouterService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.isDoneLoading$ = this.authService.isDoneLoading$;

        this.authService.isAuthenticated$
            .pipe(
                untilDestroyed(this),
                debounceTime(300),
                switchMap((isAuthenticated: boolean) => {
                    if (isAuthenticated) {
                        this.isRequestingUser = true;
                        return this.userService.getUserFromOauth();
                    }
                    return of(null);
                })
            )
            .subscribe((user: PortalUser) => {
                this.user = user;
                this.isRequestingUser = false;

                if (this.user && this.router.url === '/login') {
                    //Bug 32109 - ONLY IMPLEMENT INITIAL ROUTING IF NAVIGATING FROM THE LOGIN PAGE
                    this.handleDestinationRouting();
                }
            });

        this.authService.runInitialLoginSequence();
    }

    ngOnDestroy(): void {}

    logout(): void {
        this.authService.logout();
    }

    hasLoggedIn(): boolean {
        return this.authService.hasValidToken();
    }

    isFundingSpecialist(): boolean {
        return this.user && this.user.isFundingSpecialist();
    }

    isFundingDirector(): boolean {
        return this.user && this.user.isFundingDirector();
    }

    isUpdUser(): boolean {
        return this.user != null;
    }

    private handleDestinationRouting(): void {
        if (this.user) {
            let routeTo = this.oauthService.state;
            if (routeTo && routeTo !== '/login') {
                this.targetApplication = 'Redirecting...';
                this.router.navigateByUrl(routeTo);
                return;
            }
            if (this.user.isFundingSpecialist()) {
                this.targetApplication = 'Funding Portal';
                this.appRouterService.routeToFundingPortal();
            } else if (this.user.isFundingDirector()) {
                this.targetApplication = 'Funding Portal Administration';
                this.appRouterService.routeToFundingPortalAdministration();
            } else {
                this.targetApplication = 'Universal Payers Database';
                this.appRouterService.routeToUpd();
            }
        }
    }
}
