export const CsrAdminGroups: string[] = [
    '9dcff074-bd18-46c2-8de1-80d21424de12', // CSRQueue_Admin_Users (parent group)
    '9d9ab479-8df4-4bb4-bf58-9e558a9cbbfa', // CSRQueue_Branch_Manager
    '88cf8adb-809d-43e2-8e0b-2cdad6a1b5f4' // CSRQueue_RAD
];
export const CsrUserGroups: string[] = [
    'e83f177a-f170-4ab5-82df-a37658a79247', // CSRQueue_Users (parent group)
    'b4a1257c-286b-4743-8537-f74f4b0f4284', // CSRQueue_General_Manager
    '3ce29c4c-0f91-4058-a93a-1e16eb435292', // CSRQueue_CSR
    '35899b45-e4a0-425d-b285-f2f165f24f34' // CSRQueue_Area_Manager
];
export const ChromeRaLinkGroups: string[] = [
    '0f181952-0554-420f-9a3a-a4d694698df7' // APP_PilotGroup
];
