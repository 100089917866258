import { OAuthModuleConfig } from 'angular-oauth2-oidc';
import {
    dmsApiBaseUrl,
    dmsApiWorkOrderBaseUrl,
    updApiBaseUrl,
    gatewayBaseUrl,
    apiWorkQueueBaseUrl,
    apiWorkOrderBaseUrl
} from '../../../environments/environment';

export const AuthModuleConfig: OAuthModuleConfig = {
    resourceServer: {
        allowedUrls: [
            dmsApiBaseUrl,
            dmsApiWorkOrderBaseUrl,
            updApiBaseUrl,
            gatewayBaseUrl,
            apiWorkOrderBaseUrl,
            apiWorkQueueBaseUrl
        ],
        sendAccessToken: false
    }
};
