import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';
import { LogType } from '../models/logType';

@Injectable({ providedIn: 'root' })
export class LoggingService {
    log(type: LogType, msg: any, ...params: any[]) {
        if (type === LogType.DEBUG) {
            this.debug(msg, ...params);
        } else if (type === LogType.WARN) {
            this.warn(msg, ...params);
        } else if (type === LogType.ERROR) {
            this.error(msg, ...params);
        } else {
            this.info(msg, ...params);
        }
    }
    error(msg: any, ...params: any[]): void {
        console.error(moment().format() + ': ', msg, params);
    }
    warn(msg: any, ...params: any[]): void {
        if (!environment.production) console.warn(moment().format() + ': ', msg, params);
    }
    info(msg: any, ...params: any[]): void {
        if (!environment.production) console.info(moment().format() + ': ', msg, params);
    }
    debug(msg: any, ...params: any[]): void {
        if (!environment.production) console.debug(moment().format() + ': ', msg, params);
    }
}
