import { Injectable, isDevMode } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import { authConfig } from '@env/oauth.config';
import { Router } from '@angular/router';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private oAuthService: OAuthService, private router: Router) {}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        const token = this.oAuthService.getIdToken();
        if (token && this.oAuthService.hasValidIdToken()) {
            const claims = this.oAuthService.getIdentityClaims();
            let headers = {
                Authorization: `Bearer ${token}`
            };

            if (isDevMode()) {
                headers['nsm-user-oid'] = claims['oid'];
                headers['nsm-username'] = claims['preferred_username'];
                headers['nsm-user-group-ids'] = claims['groups'] ? (<string[]>claims['groups']).join(',') : '';
            }
            request = request.clone({
                setHeaders: headers
            });
        }
        return next.handle(request);
    }
}
