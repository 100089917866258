import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { NsmBaseFieldComponent } from '../nsm-base-field.component';

@Component({
    selector: 'nsm-currency-field',
    templateUrl: './nsm-currency-field.component.html',
    styleUrls: ['./nsm-currency-field.component.scss']
})
export class NsmCurrencyFieldComponent extends NsmBaseFieldComponent implements OnInit {
    @HostBinding('class') class: string = 'nsm-currency-field';

    constructor() {
        super();
    }

    ngOnInit(): void {}
}
