<div class="nsm-field-control-wrapper" [class.has-errors]="shouldShowErrors()">
    <label [attr.for]="id" class="nsm-field-label"
        >{{ label }}
        <nsm-required-marker *ngIf="required"> </nsm-required-marker>
    </label>
    <div class="nsm-field-control-group" [class.has-icon]="iconClass != null">
        <kendo-fileselect [formControl]="control" [multiple]="false" [restrictions]="restrictions">
            <kendo-fileselect-messages [dropFilesHere]="'Drop your file here'" [select]="'Select file...'">
            </kendo-fileselect-messages>
        </kendo-fileselect>
        <span class="field-errors" *ngIf="shouldShowErrors()">
            <ng-content select="[errors]"></ng-content>
        </span>
    </div>
</div>
