<div class="nsm-field-control-wrapper" [class.has-errors]="shouldShowErrors()">
    <label [attr.for]="id" class="nsm-field-label">{{ label }} </label>
    <kendo-datepicker
        [formControl]="control"
        [attr.id]="id"
        [attr.aria-describedby]="describedById"
        [max]="max"
        [min]="min"
        [format]="format"
        [formatPlaceholder]="formatPlaceholder"
        style="line-height: 53px;"
    ></kendo-datepicker>
    <span class="nsm-field-errors" *ngIf="shouldShowErrors()">
        <ng-content select="[errors]"></ng-content>
    </span>
</div>
