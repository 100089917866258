import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { FeatureOptionsService } from '@app/core/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ReportingGuard implements CanActivate {
    constructor(private router: Router, private featureOptionsService: FeatureOptionsService) {}

    canActivate(): Observable<boolean> {
        return this.featureOptionsService.isReportingEnabled().pipe(
            map((isReportingEnabled: boolean) => {
                if (!isReportingEnabled) {
                    this.router.navigate(['/no-permissions']);
                    return false;
                }

                return true;
            })
        );
    }
}
