import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OAuthErrorEvent, OAuthService, UserInfo } from 'angular-oauth2-oidc';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { UserService } from '..';
import { LoggingService } from '../services/logging.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
    public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

    private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
    public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

    public userInfo: UserInfo;

    /**
     * Publishes `true` if and only if (a) all the asynchronous initial
     * login calls have completed or errorred, and (b) the user ended up
     * being authenticated.
     *
     * In essence, it combines:
     *
     * - the latest known state of whether the user is authorized
     * - whether the ajax calls for initial log in have all been done
     */
    public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([
        this.isAuthenticated$,
        this.isDoneLoading$
    ]).pipe(map(values => values.every(b => b)));

    private targetStateUrl: string;

    constructor(
        private oauthService: OAuthService,
        private router: Router,
        private userService: UserService,
        private logger: LoggingService
    ) {
        // Useful for debugging:
        this.oauthService.events.subscribe(event => {
            if (event instanceof OAuthErrorEvent) {
                this.logger.error('OAuth Error');
            } else {
                //this.logger.info(`OAuth Info: ${event.type}`);
            }
        });

        // This is tricky, as it might cause race conditions (where access_token is set in another
        // tab before everything is said and done there.
        // TODO: Improve this setup.
        window.addEventListener('storage', event => {
            // The `key` is `null` if the event was caused by `.clear()`
            //this.logger.debug('storage event hit');
            if (event.key !== 'access_token' && event.key !== null) {
                return;
            }

            this.logger.warn(
                'Noticed changes to access_token (most likely from another tab), updating isAuthenticated'
            );
            this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());

            if (!this.oauthService.hasValidAccessToken()) {
                //this.logger.debug('hasValidAccessToken = false; routerUrl: {0}', router.url);
                this.navigateToLoginPage(router.url);
            }
        });

        this.oauthService.events.subscribe(_ => {
            this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
        });

        this.oauthService.events.pipe(filter(e => ['token_received'].includes(e.type))).subscribe(e => {
            //this.logger.debug('token_recieved(e)', router.url);
            //this.logger.debug('Setting OIDC. Current Value: ', this.oauthService.oidc);
            // this.userInfo
            //     ? this.userInfo
            //     : this.oauthService
            //           .loadUserProfile()
            //           .then(p => {
            //               this.userInfo = p;
            //           })
            //           .catch(err => {
            //               this.logger.error(err);
            //           })
            //           .finally(() => {
            //               this.oauthService.oidc = true;
            //               this.logger.debug('Set OIDC', this.oauthService.oidc);
            //           });
        });

        this.oauthService.events
            .pipe(filter(e => ['session_terminated', 'session_error'].includes(e.type)))
            .subscribe(e => {
                this.logger.debug('session_terminated/session_error');
                this.navigateToLoginPage();
            });
        this.oauthService.setupAutomaticSilentRefresh();
    }

    public runInitialLoginSequence(): Promise<void> {
        if (location.hash) {
            this.logger.info('Encountered hash fragment, plotting as table...');
        }
        // 0. LOAD CONFIG:
        // First we have to check to see how the IdServer is
        // currently configured:
        return this.oauthService
            .loadDiscoveryDocumentAndTryLogin()
            .then(() => {
                if (this.oauthService.hasValidAccessToken()) {
                    //this.logger.debug('Login Successful. hasValidAccessToken == true');
                    //Let's see if the session storage bit exists here as of yet.
                    //this.logger.debug('OAuth test:  getIdentityClaims()', this.oauthService.getIdentityClaims());
                    this.userInfo = this.oauthService.getIdentityClaims()[0];
                    return Promise.resolve();
                } else {
                    if (window.location.pathname.indexOf('logout') === -1) {
                        this.login(this.targetStateUrl ? this.targetStateUrl : window.location.pathname);
                    }
                }
            })
            .then(() => {
                this.isDoneLoadingSubject$.next(true);

                // Check for the strings 'undefined' and 'null' just to be sure. Our current
                // login(...) should never have this, but in case someone ever calls
                // initImplicitFlow(undefined | null) this could happen.
                if (
                    this.oauthService.state &&
                    this.oauthService.state !== 'undefined' &&
                    this.oauthService.state !== 'null'
                ) {
                    let stateUrl = this.oauthService.state;
                    if (stateUrl.startsWith('/') === false) {
                        this.logger.info('Decoding URI Component');
                        stateUrl = decodeURIComponent(stateUrl);
                    }
                    this.logger.info(
                        `There was state of ${this.oauthService.state}, so we are sending you to: ${stateUrl}`
                    );
                    this.logger.warn('Navigating from saved sate.');
                    this.router.navigateByUrl(stateUrl);
                } else {
                    this.logger.warn('Navigating from initial navigation.');
                    this.router.initialNavigation();
                }
            })
            .catch(e => {
                this.logger.error(e);
                this.isDoneLoadingSubject$.next(true);
            });
    }

    public setState(targetUrl?: string): void {
        //this.logger.debug('setState()', targetUrl);
        this.targetStateUrl = targetUrl;
    }

    public login(targetUrl?: string): void {
        // Note: before version 9.1.0 of the library you needed to
        // call encodeURIComponent on the argument to the method.
        //this.logger.debug('Initializing Implicit Flow via manual login method.');
        this.oauthService.initImplicitFlow(targetUrl || this.router.url);
    }

    public logout(): void {
        this.oauthService.logOut();
    }

    public refresh(): void {
        this.logger.debug('SilentRefresh()');
        this.oauthService.silentRefresh();
    }

    public hasValidToken(): boolean {
        return this.oauthService.hasValidAccessToken();
    }

    // These normally won't be exposed from a service like this, but
    // for debugging it makes sense.
    public get accessToken(): string {
        //this.logger.debug('accessToken', this.oauthService.getAccessToken());
        return this.oauthService.getAccessToken();
    }

    public get refreshToken(): string {
        //this.logger.debug('refreshToken', this.oauthService.getRefreshToken());
        return this.oauthService.getRefreshToken();
    }

    public get identityClaims(): object {
        this.logger.debug('identityClaims()', this.oauthService.getIdentityClaims());
        return this.oauthService.getIdentityClaims();
    }

    public get idToken(): string {
        this.logger.debug('idToken', this.oauthService.getIdToken());
        return this.oauthService.getIdToken();
    }

    public get logoutUrl(): string {
        this.logger.debug('logoutUrl', this.oauthService.logoutUrl);
        return this.oauthService.logoutUrl;
    }

    public navigateToLoginPage(stateUrl?: string): void {
        this.setState(stateUrl);
        this.router.navigateByUrl('/login');
    }
}
