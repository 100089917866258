import { Component, OnInit } from '@angular/core';

import { AppMenuService } from './app-menu.service';
import { Observable } from 'rxjs';
import { VersionService } from '@app/core/services/version.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app-menu.component.html',
    styleUrls: ['./app-menu.component.scss']
})
export class AppMenuComponent implements OnInit {
    show$: Observable<boolean>;
    buildDetails$: Observable<string>;
    constructor(private appMenuService: AppMenuService, private versionService: VersionService) {}

    onHide(): void {
        this.appMenuService.hideMenu();
    }

    ngOnInit(): void {
        this.show$ = this.appMenuService.getMenuStateChange();
        this.buildDetails$ = this.versionService.getVersionDetails();
    }
}
