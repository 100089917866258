import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { NotificationService } from '@progress/kendo-angular-notification';
import { LoggingService } from './logging.service';

export type HandleError = <T>(operation?: string, result?: T) => (error: HttpErrorResponse) => Observable<T>;

/** Handles HttpClient errors */
@Injectable({ providedIn: 'root' })
export class HttpErrorHandler {
    constructor(private notificationService: NotificationService, private logger: LoggingService) {}

    /** Create handleError function that already knows the service name */
    createHandleError = (serviceName: string = '') => <T>(operation: string = 'operation', result = {} as T) =>
        this.handleError(serviceName, operation, result);

    /**
     * @param serviceName: name of the data service
     * @param operation: name of the failed operation
     * @param result: optional value to return as the observable result
     */
    handleError<T>(serviceName: string = '', operation: string = 'operation', result = {} as T) {
        const errorShowOptions: any = {
            content: `There was an error.`,
            hideAfter: 8000,
            position: { horizontal: 'center', vertical: 'top' },
            animation: { type: 'fade', duration: 400 },
            type: { style: 'error', icon: true }
        };

        return (error: HttpErrorResponse): Observable<T> => {
            console.log(error);
            const message =
                error.error instanceof ErrorEvent
                    ? error.error.message
                    : `{error code: ${error.status}, body: "${error.message}"}`;

            this.logger.error(message, error);

            if (error.status === 409 && error.error.message) {
                this.notificationService.show({
                    ...errorShowOptions,
                    content: error.error.message
                });
                return of(result);
            } else if (error.status === 409) {
                this.notificationService.show({
                    ...errorShowOptions,
                    content: `There was a conflict with the submitted information.`
                });
                return of(result);
            } else if (error.status !== 404) {
                this.notificationService.show({
                    ...errorShowOptions,
                    content: 'There seems to be an issue.  Please check the console.'
                });
                // Return a safe result.
                return of(result);
            } else {
                return null;
            }
        };
    }
}
