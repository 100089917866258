import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'nsm-required-marker',
    templateUrl: './required-marker.component.html',
    styleUrls: ['./required-marker.component.scss']
})
export class NsmRequiredMarkerComponent implements OnInit {
    @HostBinding('class') class: string = 'nsm-required-marker-wrapper';

    @Input()
    control: FormControl;

    constructor() {}

    ngOnInit(): void {}

    hasRequiredValidators(): boolean {
        if (this.control && this.control.validator) {
            const validator = this.control.validator(this.control);
            if (validator && validator.required) {
                return true;
            }
        }
        return false;
    }
}
