import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { FormControl, AbstractControl } from '@angular/forms';

@Component({
    selector: 'nsm-field-error',
    templateUrl: './field-error.component.html',
    styleUrls: ['./field-error.component.scss']
})
export class NsmFieldErrorComponent {
    @HostBinding('class') class: string = 'nsm-field-error';

    constructor() {}
}
