import { WorkBundle } from '../admin/models';

export class QueueGroup {
    activeDirectoryId: string;
    displayName: string;
    bundles: Array<WorkBundle> = [];
    isActive: boolean = true;

    getName(): string {
        return this.displayName;
    }

    hasExistingBundle(id: number): boolean {
        return this.bundles && this.bundles.find((wb: WorkBundle) => wb.id === id) != null;
    }

    hasBundleForBranch(branchId: number): boolean {
        return this.bundles && this.bundles.find((wb: WorkBundle) => wb.getBranch() === branchId) != null;
    }

    replaceBundle(bundle: WorkBundle): void {
        const index = this.bundles.findIndex((wb: WorkBundle) => {
            return wb.getBranch() === bundle.getBranch();
        });
        this.bundles.splice(index, 1, bundle);
    }

    removeBundle(bundle: WorkBundle): void {
        const index = this.bundles.findIndex((wb: WorkBundle) => {
            return wb.id === bundle.id;
        });

        this.bundles.splice(index, 1);
    }
}
