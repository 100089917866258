<div class="nsm-field-control-wrapper" [class.has-errors]="shouldShowErrors()">
    <label [attr.for]="id" class="nsm-field-label"
        >{{ label }} <nsm-required-marker [control]="control" *ngIf="required"> </nsm-required-marker
    ></label>
    <kendo-dropdownlist
        #dropdownfield
        [data]="options"
        [textField]="'text'"
        [valueField]="'value'"
        [defaultItem]="defaultItem"
        [valuePrimitive]="true"
        [formControl]="control"
        class="nsm-field-control"
        [virtual]="options?.length > 50"
        (valueChange)="valueChange($event)"
        [filterable]="true"
        (filterChange)="handleFilter($event)"
        *ngIf="!multiselect"
    >
    </kendo-dropdownlist>

    <kendo-multiselect
        #multiselectfield
        *ngIf="multiselect"
        [placeholder]="placeholder"
        [data]="options"
        [textField]="'text'"
        [valueField]="'value'"
        [formControl]="control"
        (valueChange)="valueChange($event)"
        class="nsm-field-control"
    >
        <ng-template kendoMultiSelectItemTemplate let-dataItem>
            <input type="checkbox" kendoCheckBox [checked]="isItemSelected(dataItem.value)" />
            <label *ngIf="!dataItem.enablePayerMetaData" class="k-checkbox-label">{{ dataItem.text }}</label>
            <label *ngIf="dataItem.enablePayerMetaData" class="k-checkbox-label"
                ><strong>{{ dataItem.text }} *</strong></label
            >
        </ng-template>
    </kendo-multiselect>
</div>
