import { AdUser } from '@app/core/models';
import { WorkBundle } from '../admin/models/resources/work.bundle';
import { Person } from './person';

export class QueueUser extends Person implements AdUser {
    email: string;
    activeDirectoryId: string;
    bundles: Array<WorkBundle> = [];
    isActive: boolean;
    authid: number;

    hasExistingBundle(id: number): boolean {
        return this.bundles && this.bundles.find((wb: WorkBundle) => wb.id === id) != null;
    }

    hasBundleForBranch(branchId: number): boolean {
        return this.bundles && this.bundles.find((wb: WorkBundle) => wb.getBranch() === branchId) != null;
    }

    replaceBundle(bundle: WorkBundle): void {
        const index = this.bundles.findIndex((wb: WorkBundle) => {
            return wb.getBranch() === bundle.getBranch();
        });
        this.bundles.splice(index, 1, bundle);
    }

    removeBundle(bundle: WorkBundle): void {
        const index = this.bundles.findIndex((wb: WorkBundle) => {
            return wb.id === bundle.id;
        });

        this.bundles.splice(index, 1);
    }
    removeBundleByType(bundle: WorkBundle, bundleType: string): void {
        const index = this.bundles.findIndex((wb: WorkBundle) => {
            return wb.id === bundle.id;
        });

        this.bundles.splice(index, 1);
    }
}
