import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { NsmBaseFieldComponent } from '../nsm-base-field.component';
import { FileRestrictions, FileInfo, SelectEvent, RemoveEvent } from '@progress/kendo-angular-upload';

@Component({
    selector: 'nsm-file-upload',
    templateUrl: './nsm-file-upload.component.html',
    styleUrls: ['../nsm-base-field.component.scss', './nsm-file-upload.component.scss']
})
export class NsmFileUploadFieldComponent extends NsmBaseFieldComponent implements OnInit {
    @HostBinding('class') class: string = 'nsm-file-upload';

    @Input()
    restrictions: FileRestrictions;

    constructor() {
        super();
    }

    ngOnInit(): void {}
}
