import { Directive, ElementRef, AfterViewChecked } from '@angular/core';

@Directive({
    selector: '[appFocusFirstInput]'
})
export class FocusFirstInputDirective implements AfterViewChecked {
    fired: boolean = false;

    constructor(private el: ElementRef) {}

    ngAfterViewChecked(): void {
        if (!this.fired) {
            const elems = this.el.nativeElement.getElementsByTagName('input');
            if (elems.length > 0) {
                console.log(elems);
                elems[0].focus();
            }
            this.fired = true;
        }
    }
}
