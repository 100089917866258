import { Input, Directive } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as uuid from 'uuid';

@Directive()
export class NsmBaseFieldComponent {
    @Input()
    label: string;

    @Input()
    required: boolean;

    @Input()
    control: FormControl;

    @Input()
    placeholder: string = '';

    @Input()
    iconClass: string;

    id: string = uuid.v4();
    describedById: string = `descby-${this.id}`;

    constructor() {}

    public shouldShowErrors(): boolean {
        return this.control && this.control.dirty && this.control.invalid;
    }
}
