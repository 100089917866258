import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { FieldOption } from '@app/domain';
import { NsmBaseFieldComponent } from '../nsm-base-field.component';

@Component({
    selector: 'nsm-text-field',
    templateUrl: './nsm-text-field.component.html',
    styleUrls: ['../nsm-base-field.component.scss', './nsm-text-field.component.scss']
})
export class NsmTextFieldComponent extends NsmBaseFieldComponent implements OnInit {
    @HostBinding('class') class: string = 'nsm-text-field';

    @Input()
    maxLength: number = 250;

    @Input()
    multiline: boolean;

    constructor() {
        super();
    }

    ngOnInit(): void {}

    valueChange(e: any): void {
        this.control.updateValueAndValidity();
    }

    isItemSelected(itemText: string): boolean {
        const values = this.control.value;

        if (!values) {
            return false;
        }

        return values.some((item: FieldOption) => {
            return item.value.toString().toLowerCase() === itemText.toString().toLowerCase();
        });
    }
}
