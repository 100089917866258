import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PortalUser } from '../models';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({ providedIn: 'root' })
export class UserService {
    private user: PortalUser = null;

    constructor(private oAuthService: OAuthService, private http: HttpClient) {}

    getUser(): PortalUser {
        return this.user;
    }

    public getUserFromOauth(): Observable<PortalUser> {
        if (this.user) return of(this.user);

        const claims = this.oAuthService.getIdentityClaims();
        const userObjectId = claims ? claims['oid'] : '';
        return this.http.get<PortalUser>(environment.userAuthorizationApi + `/${userObjectId}`).pipe(
            map((object: any) => {
                this.user = Object.assign(new PortalUser(), object);
                if (object.bundles) {
                    object.bundles.forEach((bundle: any) => {
                        if (bundle.branches) bundle.branches.forEach((branch: any) => this.user.branches.push(branch));
                    });
                }

                return this.user;
            })
        );
    }
    public GetPayerCredentials(): Observable<Array<PortalUser>> {
        return this.http.get<Array<PortalUser>>(environment.userAuthorizationApi + '/GetPayerCredential');
    }
    public getUsersGroups(): Observable<Array<string>> {
        return Observable.create((observer: any) => {
            if (this.user) {
                observer.next(this.user.groups);
                observer.complete();
            } else {
                this.getUserFromOauth().subscribe((user: PortalUser) => {
                    // Store the groups so we don't have to make another call.
                    this.user = Object.assign(new PortalUser(), user);
                    observer.next(this.user.groups);
                    observer.complete();
                });
            }
        });
    }
}
