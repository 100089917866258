import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HandleError, HttpErrorHandler } from '@app/core/services';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class VersionService {
    private handleError: HandleError;
    constructor(private http: HttpClient, private httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('UpdBranchService');
    }

    getVersionDetails(): Observable<string> {
        return this.http
            .get('/assets/BuildDetails.txt', { responseType: 'text' })
            .pipe(catchError(this.handleError('Get Build Version', 'Could not retrieve Build Version')));
    }
}
