import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from './core/authentication';

@Component({
    selector: 'app-funding-portal',
    templateUrl: 'app.component.html'
})
export class AppComponent {
    constructor(private authenticationService: AuthenticationService, router: Router) {
        this.authenticationService.runInitialLoginSequence();
    }
}
