import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'fpYesNo'
})
export class FpYesNoPipe implements PipeTransform {
    transform(value: any, ...args: any[]): any {
        if (value === true) return 'Yes';
        if (value === false) return 'No';
        return '';
    }
}
