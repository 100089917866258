import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { NsmBaseFieldComponent } from '../nsm-base-field.component';
import { FieldOption } from '@app/domain';
import { FormGroup } from '@angular/forms';

/**
 * Note to developer.  Radio buttons and Angular are very specific.
 * They need to have the form group passed in and they need to have the
 * attribute name match the formControlName.  This is atypical to how the other
 * components work where we just need to pass in the form control.
 */
@Component({
    selector: 'nsm-radio-group',
    templateUrl: './nsm-radio-group.component.html',
    styleUrls: ['../nsm-base-field.component.scss', './nsm-radio-group.component.scss']
})
export class NsmRadioGroupComponent extends NsmBaseFieldComponent implements OnInit {
    @HostBinding('class') class: string = 'nsm-radio-group';

    @Input()
    group: FormGroup;

    @Input()
    options: Array<FieldOption>;

    @Input()
    groupName: string;

    constructor() {
        super();
    }

    ngOnInit(): void {}
}
