import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { NsmBaseFieldComponent } from '../nsm-base-field.component';

export enum SupportedTextFieldMasks {
    Phone = '(999) 000-0000',
}

@Component({
    selector: 'nsm-masked-text-field',
    templateUrl: './nsm-masked-text-field.component.html',
    styleUrls: ['../nsm-base-field.component.scss', './nsm-masked-text-field.component.scss'],
})
export class NsmMaskedTextFieldComponent extends NsmBaseFieldComponent implements OnInit {
    @HostBinding('class') class: string = 'nsm-masked-text-field';

    @Input()
    mask: SupportedTextFieldMasks = SupportedTextFieldMasks.Phone;

    constructor() {
        super();
    }

    ngOnInit(): void {}
}
