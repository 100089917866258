export enum CustomerTypeValues {
    REGULAR = 'Regular',
    KAISER = 'Kaiser',
    APRIA_CIGNA_REFERRAL = 'Apria/Cigna',
    PRAXAIR_A_REC_NOT_DEL = 'Praxair A – Rec not Del',
    PRAXAIR_B_NOT_REC = 'Praxair B – Not Rec',
    PRAXAIR_C_NOT_PURCH = 'Praxair C – Not Purch',
    PRAXAIR_D_SOMETHING_ELSE = 'Praxair D – Something Else',
    CARECENTRIX = 'CareCentrix',
    WORKERS_COMP = 'Workers Comp',
    NO_FAULT = 'No Fault',
    SELF_PAY = 'Self-Pay',
    EXPEDITED = 'Expedited'
}
