import { Resource } from './resource';

export class Person extends Resource {
    firstName: string;
    middleName: string;
    lastName: string;
    dateofbirth: Date;
    email: string;
    phone: string;
    get fullname() {
        return `${this.lastName}, ${this.firstName}`;
    }

    get fullName() {
        return `${this.lastName}, ${this.firstName}`;
    }

    getName(): string {
        return this.getDisplayName();
    }

    getDisplayName(): string {
        return `${this.firstName} ${this.lastName}`;
    }

    getFormattedName(): string {
        return `${this.lastName}, ${this.firstName}`;
    }
}
