import { Component, OnInit, Input, HostBinding, OnDestroy } from '@angular/core';
import { NsmBaseFieldComponent } from '../nsm-base-field.component';
import { PickListFieldOption } from '@app/domain';
import { BehaviorSubject } from 'rxjs';
import { untilDestroyed } from '@app/core/utilities/untilDestroyed';
import * as _ from 'lodash';

@Component({
    selector: 'nsm-pick-list',
    templateUrl: './nsm-pick-list.component.html',
    styleUrls: ['../nsm-base-field.component.scss', './nsm-pick-list.component.scss']
})
export class NsmPickListComponent extends NsmBaseFieldComponent implements OnInit, OnDestroy {
    @HostBinding('class') class: string = 'nsm-pick-list';

    @Input('options') set options(value: Array<PickListFieldOption>) {
        this.source$.next(value);
    }

    source$: BehaviorSubject<PickListFieldOption[]> = new BehaviorSubject<PickListFieldOption[]>([]);
    available$: BehaviorSubject<PickListFieldOption[]> = new BehaviorSubject<PickListFieldOption[]>([]);

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.source$.pipe(untilDestroyed(this)).subscribe((options: PickListFieldOption[]) => {
            this.available$.next(Object.assign([], options));
            this.removeSelectedFromAvailable();
        });
    }

    ngOnDestroy(): void {}
    addItem(item: PickListFieldOption): void {
        let selected = this.control.value;
        selected.push(item);
        this.control.updateValueAndValidity();
        this.removeSelectedFromAvailable();
        selected = this.maintainSortOrder(selected);
        this.control.setValue(selected);
    }

    removeItem(item: PickListFieldOption): void {
        this.removeFromSelected(item);
        let available = this.available$.value;
        available.push(item);
        available = this.maintainSortOrder(available);
        this.available$.next(available);
    }

    private removeSelectedFromAvailable(): void {
        this.control.value.forEach((o: PickListFieldOption) => this.removeFromAvailable(o));
    }

    private removeFromSelected(item: PickListFieldOption): void {
        const selected: PickListFieldOption[] = this.control.value;
        const index = selected.findIndex(f => f === item);

        if (index < 0) {
            return;
        }

        selected.splice(index, 1);
        this.control.setValue(selected);
    }

    private removeFromAvailable(item: PickListFieldOption): void {
        const available: PickListFieldOption[] = this.available$.value;
        const index = available.findIndex(f => f === item);

        if (index < 0) {
            return;
        }

        available.splice(index, 1);
        this.available$.next(available);
    }

    private maintainSortOrder(items: PickListFieldOption[]): PickListFieldOption[] {
        return _.sortBy(items, s => s.sortValue);
    }
}
