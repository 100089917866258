<div class="nsm-field-control-wrapper" [class.has-errors]="shouldShowErrors()">
    <div class="nsm-field-control-header" [ngClass]="{ expandable: expandable }" (click)="toggleExpanded()">
        <label [attr.for]="id" class="nsm-field-label">
            <i class="fal fa-plus" *ngIf="expandable && !expanded"></i>
            <i class="fal fa-minus" *ngIf="expandable && expanded"></i>
            {{ label }}
            <nsm-required-marker *ngIf="required" [control]="control"></nsm-required-marker>
        </label>
    </div>
    <div
        class="nsm-field-control-group"
        [class.has-icon]="iconClass != null"
        *ngIf="(expandable && expanded) || !expandable"
    >
        <span *ngIf="iconClass" class="icon {{ iconClass }}"></span>
        <span *ngFor="let optionControl of getAvailableControls(); let i = index">
            <span [class.hide]="i > optionsShown && !isVisible">
                <input
                    [value]="_options[i].value"
                    [attr.id]="id + '-' + i"
                    [formControl]="optionControl"
                    type="checkbox"
                    kendoCheckBox
                    [attr.name]="id + '-name'"
                />
                <label [attr.for]="id + '-' + i">{{ _options[i].text }}</label>
                <br />
            </span>
        </span>
        <a
            (click)="isVisible = true"
            class="nsm-field-control-more"
            *ngIf="!isVisible && _options?.length > optionsShown"
            >Show More</a
        >
    </div>
</div>
