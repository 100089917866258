import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CoreRoutingModule } from '@app/core/core.routing.module';
import { DirtyComponent } from './dirty-component/dirty.component';
import { CacheInterceptor, JwtInterceptor } from '@app/core/helpers';
import { ToastNotificationService } from '@app/core';

import { environment } from '../../environments/environment';
import {
    DirectorGuard,
    FundingSpecialistGuard,
    UpdUserGuard,
    PendingChangesGuard,
    ReportingGuard
} from './helpers/guards';
import { ApplicationInsightsModule } from '@markpieszak/ng-application-insights';
import { AuthConfig, OAuthModuleConfig, OAuthStorage, OAuthModule } from 'angular-oauth2-oidc';
import { UploadInterceptor } from './helpers/upload.interceptor';
import { AuthModuleConfig, AuthGuardWithForcedLoginService, AuthenticationService } from './authentication';
import { authConfig } from '@env/oauth.config';
import { LogoutComponent } from './landing/logout/logout.component';
import { LoginComponent, NotFoundComponent, NoPermissionsComponent, AccessDeniedComponent } from '@app/core/landing';
import { NsmKendoSharedModule } from '@app/third-party-modules/kendo/shared-kendo.module';
import { CsrAdminGuard, CsrUserGuard } from './helpers/guards/csr.guard.service';
import { AdminGuard } from './helpers/guards/admin.guard.service';

export function storageFactory(): OAuthStorage {
    return localStorage;
}

@NgModule({
    imports: [
        CommonModule,
        CoreRoutingModule,
        NsmKendoSharedModule,
        OAuthModule.forRoot(),
        ApplicationInsightsModule.forRoot({
            instrumentationKey: environment.azureAppInsightsInstrumentationKey,
            sessionRenewalMs: 2700000
        })
    ],
    declarations: [
        NotFoundComponent,
        AccessDeniedComponent,
        NoPermissionsComponent,
        LogoutComponent,
        DirtyComponent,
        LoginComponent
    ],
    exports: [RouterModule],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: UploadInterceptor, multi: true },
        { provide: AuthConfig, useValue: authConfig },
        { provide: OAuthModuleConfig, useValue: AuthModuleConfig },
        { provide: OAuthStorage, useFactory: storageFactory },
        ToastNotificationService,
        DirectorGuard,
        CsrAdminGuard,
        CsrUserGuard,
        AdminGuard,
        FundingSpecialistGuard,
        UpdUserGuard,
        PendingChangesGuard,
        ReportingGuard,
        AuthGuardWithForcedLoginService,
        AuthenticationService
    ]
})
export class CoreModule {}
